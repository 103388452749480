import React from "react"

export default function Cache() {
    return (
        <h1>SSR: Server Side Rendering</h1>
    )
}

export async function getServerData() {
    return {
        headers: {
            'Cache-Control': 'public, max-age=604800, s-maxage=604800, stale-while-revalidate=86400',
        }
    }
}